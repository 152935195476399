import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Plot from 'react-plotly.js';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // Required for Amplify UI components
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function AppContent() {
  const [equipmentId, setEquipmentId] = useState(1);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)); // Default to one week ago
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Use the useAuthenticator hook at the top level
  const { signOut } = useAuthenticator();

  const handlePlot = async () => {
    setLoading(true);
    setError(null);
    setChartData(null);

    const startTimestamp = Math.floor(startDate.getTime() / 1000);
    const endTimestamp = Math.floor(endDate.getTime() / 1000);

    const url = `https://4zp7jbl41g.execute-api.us-west-2.amazonaws.com/test/hs_measure?schema_name=CA-VAN-0001&equipment_id=${equipmentId}&start_time=${startTimestamp}&end_time=${endTimestamp}`;

    try {
      const response = await axios.get(url);
      console.log("Request URL:", url);
      console.log("Full response:", response.data);

      if (response.data.statusCode !== 200) {
        throw new Error(`API Error: ${response.data.statusCode}`);
      }

      const data = JSON.parse(response.data.body);
      console.log("Parsed data:", data);

      const temperature = [];
      const humidity = [];
      const temperature1 = [];
      const timestamps = [];

      data.forEach(entry => {
        const date = new Date(entry.timestamp_utc * 1000); // Convert Unix timestamp to JS Date
        if (!timestamps.includes(date)) {
          timestamps.push(date);
        }

        if (entry.point_id === 'temperature') {
          temperature.push({ x: date, y: parseFloat(entry.value) });
        } else if (entry.point_id === 'humidity') {
          humidity.push({ x: date, y: parseFloat(entry.value) });
        } else if (entry.point_id === 'temperature_1') {
          temperature1.push({ x: date, y: parseFloat(entry.value) });
        }
      });

      setChartData({
        temperature: temperature.length ? temperature : null,
        humidity: humidity.length ? humidity : null,
        temperature1: temperature1.length ? temperature1 : null,
      });
    } catch (err) {
      console.error('Error fetching or processing data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Equipment Measurements Dashboard</h2>
      {/* Sign Out Button */}
      <button onClick={signOut} style={{ marginBottom: '20px' }}>
        Sign Out
      </button>

      {/* Equipment ID Dropdown */}
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="equipment-select" style={{ marginRight: '10px' }}>Equipment ID:</label>
        <select
          id="equipment-select"
          value={equipmentId}
          onChange={(e) => setEquipmentId(parseInt(e.target.value))}
        >
          {[...Array(10)].map((_, idx) => (
            <option key={idx + 1} value={idx + 1}>{idx + 1}</option>
          ))}
        </select>
      </div>

      {/* Date Pickers */}
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Start Date:</label>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          maxDate={endDate}
        />
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ marginRight: '22px' }}>End Date:</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy-MM-dd"
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>

      {/* Plot Button */}
      <button onClick={handlePlot} disabled={loading} style={{ padding: '10px 20px', cursor: 'pointer' }}>
        {loading ? 'Loading...' : 'Plot'}
      </button>

      {/* Error Message */}
      {error && (
        <div style={{ marginTop: '20px', color: 'red' }}>
          <strong>Error:</strong> {error}
        </div>
      )}

      {/* Plotting the results */}
      {chartData && (
        <div style={{ marginTop: '40px' }}>
          <Plot
            data={[
              chartData.temperature && {
                x: chartData.temperature.map(point => point.x),
                y: chartData.temperature.map(point => point.y),
                type: 'scatter',
                mode: 'lines+markers',
                name: 'Temperature (°C)',
                marker: { color: 'red' },
              },
              chartData.temperature1 && {
                x: chartData.temperature1.map(point => point.x),
                y: chartData.temperature1.map(point => point.y),
                type: 'scatter',
                mode: 'lines+markers',
                name: 'Temperature 1 (°C)',
                marker: { color: 'orange' },
              },
              chartData.humidity && {
                x: chartData.humidity.map(point => point.x),
                y: chartData.humidity.map(point => point.y),
                type: 'scatter',
                mode: 'lines+markers',
                name: 'Humidity (%)',
                marker: { color: 'blue' },
                yaxis: 'y2',
              },
            ].filter(Boolean)}
            layout={{
              title: `Equipment ID: ${equipmentId} Measurements`,
              xaxis: {
                title: 'Time',
                type: 'date',
              },
              yaxis: {
                title: 'Temperature (°C)',
                side: 'left',
              },
              yaxis2: {
                title: 'Humidity (%)',
                overlaying: 'y',
                side: 'right',
              },
              legend: { orientation: 'h', x: 0.1, y: -0.2 },
              margin: { t: 50, b: 100 },
            }}
            config={{
              responsive: true,
              displayModeBar: true,
              scrollZoom: true,
            }}
          />
        </div>
      )}
    </div>
  );
}

function App() {
  return (
    <Authenticator>
      <AppContent />
    </Authenticator>
  );
}

export default App;
